import get from "lodash.get";

import { getWoundHeader } from "../get_wound_header";
import { getWoundImage } from "../get_wound_image";
import { getWoundImages } from "../get_wound_images";
import { getFilteredCharts } from "../get_filtered_charts";
import { tableForAnswersAndSchema } from "../../table_for_answers_and_schema";
import { getDimensionsTable } from "../get_dimensions_table";
import { getHPRTable } from "../get_hpr_table";
import { getESignature } from "../../get_e_signature";
import { getPatientRow } from "../../common_components/get_patient_row";
import { formatBodyLocationString } from "src/utils/format_location_string";

/**
 * Get the content (body) portion of the Assessment PDF, Formatted for pdfMake
 * @param {Object} data
 * @param {Object} data.user - the user
 * @param {Object} data.revision - the revision
 * @param {Object} data.assessmentAnswer - the most recent aa for the revision
 * @param {Object[]}  data.progressSchemas - array of assessment schemas
 * @param {Object[]} data.treatmentSchemas - array of assessment schemas
 * @param {Object[]} data.attributesSchemas - array of assessment schemas
 * @param {Object[]} data.painSchemas - array of assessment schemas
 *
 * @param {Object} images
 * @param {string} images.wound - data url of wound
 * @param {string} images.closureChart - data url of closure chart
 * @param {string} images.pushChart - data url of push chart
 *
 * @return {Object} JSON representation of the pdf content
 */
export async function getBody(pdfContentObjects, options) {
  return await pdfContentObjects.reduce(async (aggPromise, content) => {
    const {
      // resources
      revision,
      assessmentAnswer,
      schemas,
      latestSignedEvent,

      // flags
      isAutodepth,
      revisionIsFirstInSeries,

      // images
      images
    } = content;

    const {
      wound,
      closureChart,
      pushChart,
      hprChart,
      bwatChart,
      imageText
    } = images;

    const {
      hprScoreEnabled,
      schemaLocalizationDictionary,
      subtypeKeysForSchema
    } = options;

    // Create tables
    const dimensionsTable = getDimensionsTable(revision, isAutodepth);
    let hprTable;

    const agg = await aggPromise;

    if (hprScoreEnabled) {
      hprTable = getHPRTable(revision);
    }

    const mapSchemasToTable = schema =>
      tableForAnswersAndSchema({
        assessmentAnswer,
        revisionIsFirstInSeries,
        assessmentSchema: schema
      });

    const describeTables = schemas.describe.map(mapSchemasToTable);
    const attributesTables = schemas.attributes.map(mapSchemasToTable);
    const progressTables = schemas.progress.map(mapSchemasToTable);
    const treatmentTables = schemas.treatment.map(mapSchemasToTable);

    const assessmentId = get(assessmentAnswer, "attributes.assessmentId");
    const schemaLocalizationDictionaryForRevision =
      schemaLocalizationDictionary[assessmentId];

    // Special handling for location in describe table
    const {
      location,
      sublocation,
      laterality
    } = assessmentAnswer.attributes.answersJson;

    const bodyLocation = location
      ? formatBodyLocationString(
          {
            location,
            sublocation,
            lateralities: laterality
          },
          schemaLocalizationDictionaryForRevision
        )
      : "Location not set";

    describeTables[0][0].table.body.forEach(item => {
      if (item[0].text.match(/Location$/)) {
        const index = describeTables[0][0].table.body.indexOf(item);
        describeTables[0][0].table.body[index][1].text = bodyLocation;
      }
    });

    // Top of pdf - patient info
    agg.push(getPatientRow(content, { branding: options.branding }));

    // wound header, image and dimensions
    agg.push({
      columns: [
        {
          stack: [
            getWoundHeader(content, {
              ...options,
              schemaLocalizationDictionary: schemaLocalizationDictionaryForRevision,
              subtypeKeysForSchema
            }),
            ...(dimensionsTable ? [dimensionsTable] : []),
            ...(hprTable ? [hprTable] : [])
          ],
          width: "*"
        },
        getWoundImage(wound, imageText)
      ],
      style: "columnBody"
    });

    // Graphs
    const charts = getFilteredCharts({
      closureChart,
      pushChart,
      bwatChart,
      hprChart
    });

    agg.push(...charts);

    let pageBreak = "before";

    // Schema tables
    const addTableIfExists = table => {
      if (table) {
        agg.push({
          columns: [table],
          style: "columnBody",
          alignment: "left",
          pageBreak
        });

        pageBreak = null;
      }
    };

    describeTables.forEach(addTableIfExists);
    attributesTables.forEach(addTableIfExists);
    treatmentTables.forEach(addTableIfExists);
    progressTables.forEach(addTableIfExists);

    //Context photos
    const frames = get(revision, "attributes.frames", []);

    const woundImages = await getWoundImages(frames);

    let marginTop = 50;

    if (woundImages.deltaWoundImage || woundImages.fluorescentWoundImage) {
      pageBreak = "before";
      marginTop = 600;

      agg.push({
        table: {
          widths: ["100%"],
          body: [[{ text: "CONTEXT PHOTOS", style: "tableHeaderCell" }]]
        },
        layout: "PDFTableStyle",
        pageBreak
      });
    }

    if (woundImages.deltaWoundImage) {
      agg.push({
        columns: [woundImages.deltaWoundImage, woundImages.absoluteWoundImage],
        alignment: "center",
        marginTop: 20
      });
    }

    if (woundImages.fluorescentWoundImage) {
      agg.push({
        columns: [woundImages.fluorescentWoundImage],
        alignment: "center",
        marginTop: 10
      });
    }

    //E-Signature
    const signatureText = getESignature(
      latestSignedEvent,
      get(assessmentAnswer, "attributes.signState", "")
    );

    agg.push({
      marginTop,
      pageBreak,
      text: signatureText,
      style: "paragraph"
    });

    return agg;
  }, []);
}
