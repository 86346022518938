export const overallProgressDefault = {
  new: "New",
  better: "Improving",
  stable: "Stable",
  stalled: "Stalled",
  worse: "Deteriorating",
  healed: "Resolved",
  monitoring: "Monitoring",
  unknown: "Status Unknown",
  unableToEvaluateDueTo: "Unable to evaluate due to",
  unableToObserve: "Unable to Observe",
  unableToObserveTx: "Unable to Observe/Wound Tx Not Provided"
};
